import "./App.css";
import React from "react";

function App() {
  const [token, setToken] = React.useState(null);

  React.useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    const tokenValue = params.get("token");
    if (tokenValue) {
      setToken(tokenValue);
    } else {
      // Redirect to a different domain when token is not found
      window.location.href = "https://api.prod.paigo.tech/users/login";
    }
  }, []);
  return token ? (
    <div className="App" height="100%">
      <iframe
        src={`https://paigo.retool.com/embedded/public/a5603ce8-5749-45b0-b82d-c680179478ec?token=${token}`}
        width="100%"
        height="100%"
        title="paigo-dashboard"
      />
    </div>
  ) : (
    <div></div>
  );
}

export default App;
